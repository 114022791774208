<template>
    <div :class="$style.blogCard">
        <a :href="frameworkLink" target="_blank">
            <img v-if="thumbnailSrc" :class="$style.imageIcon" alt="" :src="thumbnailSrc" />
            <img v-else :class="$style.imageIcon" alt="" src="@/assets/no-thumbnail.png" />
        </a>
        <div :class="$style.text7">
            <div :class="$style.badgeGroup">
                <!-- Badges can be dynamic too if needed -->
                <div :class="$style.badge1">
                    <img :class="$style.paletteIcon" alt="" src="@/components/eccr/assets/palette.svg" />
                    <div :class="$style.text8">Design</div>
                </div>
                <div :class="$style.badge1">
                    <img :class="$style.paletteIcon" alt="" src="@/components/eccr/assets/palette.svg" />
                    <div :class="$style.text8">Design</div>
                </div>
                <div :class="$style.badge3">
                    <img :class="$style.paletteIcon" alt="" src="@/components/eccr/assets/palette.svg" />
                    <div :class="$style.text8">Design</div>
                </div>
                <div :class="$style.commentsSave">
                    <button class="button is-medium is-rounded is-primary is-light" :disabled="busy" :class="$style.save"
                        @click="toggleFavorite">
                        <!-- <img :class="$style.paletteIcon" alt="" src="@/components/eccr/assets/bookmark-outline.svg" />
                        <div :class="$style.share">Save</div> -->
                        <span v-if="busy">Saving  <span class="fas fa-regular fa-paper-plane"></span></span>
                        <span v-else-if="currentlyFavorited">
                            <i class="fas fa-solid fa-bookmark"></i> Saved
                        </span>
                        <span v-else>
                            <i class="far fa-regular fa-bookmark"></i> Save
                        </span>
                    </button>
                    <div :class="$style.likes">
                        <img :class="$style.shareAllOutlineIcon" alt=""
                            src="@/components/eccr/assets/share-all-outline.svg" />
                        <div :class="$style.share">Share</div>
                    </div>
                </div>
            </div>
            <a :href="frameworkLink" target="_blank">
                <b :class="$style.title">{{ frameworkName }}</b>
            </a>
            <div :class="$style.preview">{{ frameworkDescription }}</div>
            <div :class="$style.buttonAuthorWrapper">
                <div :class="$style.buttonAuthor">
                    <div :class="$style.commentsSave1">
                        <div :class="$style.likes">
                            <img :class="$style.paletteIcon" alt=""
                                src="@/components/eccr/assets/message-caption.svg" />
                            <div :class="$style.publishedOnJan">{{ publishDate }}</div>
                        </div>
                        <div :class="$style.likes">
                            <img :class="$style.paletteIcon" alt=""
                                src="@/components/eccr/assets/message-caption.svg" />
                            <div :class="$style.publishedOnJan">{{ lastUpdatedDate }}</div>
                        </div>
                        <div :class="$style.likes2">
                            <img :class="$style.paletteIcon" alt=""
                                src="@/components/eccr/assets/message-caption.svg" />
                            <div :class="$style.share">{{ itemsCount }} items</div>
                        </div>
                    </div>
                    <a :class="$style.button3" :href="frameworkLink" target="_blank">
                        <div :class="$style.textLink">View more</div>
                        <img :class="$style.paletteIcon" alt=""
                            src="@/components/eccr/assets/arrow-right-outline.svg" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    thumbnailSrc: {
        type: String
    },
    frameworkName: {
      type: String,
      required: true
    },
    frameworkLink: {
      type: String,
      required: true
    },
    frameworkURI: {
      type: String,
      required: true
    },
    frameworkDescription: {
      type: String,
      required: true
    },
    itemsCount: {
      type: Number,
      required: true
    },
    publishDate: {
      type: String,
      required: true
    },
    lastUpdatedDate: {
      type: String,
      required: true
    },
    favorited: {
        type: Boolean,
        required: false
    },
    checkIfFavorited: {
        type: Function,
        required: false
    }
  },
  data() {
    return {
        busy: false,
        currentlyFavorited: false
    };
  },
  methods: {
    toggleFavorite() {
        this.busy = true;
        this.$emit("toggle-favorite", this.$props.frameworkURI);
        setTimeout(() => { 
            if (this.checkIfFavorited !== undefined) {
                this.currentlyFavorited = this.checkIfFavorited(this.frameworkURI);
            }
            this.busy = false; 
        }, 500);
    }
  },
  mounted() {
    // alert("Favorited? " + this.favorited);
    this.currentlyFavorited = this.favorited;
  }
};
</script>
<style>
.ellipsis::after {
  content: "...";
  animation: ellipsis 1.25s infinite;
}

@keyframes ellipsis {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: "";
  }
}
</style>

<style module>
.welcomeX {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.pageHeader {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1275px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
}

.pageHeaderWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1275px;
    height: 56px;
}

.contentLayoutsInner {
    width: 1275px;
    position: relative;
    height: 56px;
}

.text {
    position: relative;
    line-height: 24px;
}

.text1 {
    position: relative;
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    color: #6b7280;
}

.info {
    width: 14px;
    position: relative;
    height: 14px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.heading {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.headingWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 310px;
    height: 24px;
}

.groupWrapper {
    position: absolute;
    top: 0.5px;
    left: 0px;
    width: 310px;
    height: 24px;
}

.firstLevelInner {
    align-self: stretch;
    position: relative;
    height: 25px;
}

.firstLevel {
    width: 310px;
    border-bottom: 1px solid #e5e7eb;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.firstLevelWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.searchOutlineIcon {
    width: 16px;
    position: relative;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}

.inputText {
    flex: 1;
    position: relative;
    line-height: 150%;
}

.content1 {
    width: 332px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.input1 {
    align-self: stretch;
    border-radius: 8px 0px 0px 8px;
    background-color: #faf9fb;
    border: 1px solid #d6d2db;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px;
}

.inputField {
    width: 384px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.button {
    border-radius: 0px 8px 8px 0px;
    background-color: #562990;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    color: #fff;
    font-family: Inter;
}

.input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: #6b7280;
    font-family: Roboto;
}

.filterIcon {
    width: 12px;
    position: relative;
    height: 12px;
    overflow: hidden;
    flex-shrink: 0;
}

.button1 {
    border-radius: 8px;
    background-color: #faf9fb;
    border: 1px solid #9678bc;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 8px;
}

.inputButtons {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.inputButtonsWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 646px;
    height: 37px;
}

.groupFrame {
    width: 646px;
    position: relative;
    height: 37px;
}

.groupContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1227px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.tableHeaderInner {
    width: 1227px;
    position: relative;
    height: 37px;
    font-size: 14px;
    color: #562990;
}

.tableHeader {
    align-self: stretch;
    border-radius: 8px 8px 0px 0px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px;
    gap: 24px;
    font-size: 24px;
    color: #111928;
    font-family: Inter;
}

.text5 {
    position: relative;
    line-height: 16px;
}

.badge {
    border-radius: 6px;
    background-color: #e5dcf4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
}

.text6 {
    position: relative;
    font-size: 14px;
    line-height: 150%;
    color: #562990;
}

.badgeParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

.separatorIcon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
}

.active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.navItem {
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 24px;
}

.navItem1 {
    align-self: stretch;
    width: 95.8px;
    display: none;
}

.navItem2 {
    align-self: stretch;
    width: 95.8px;
    border-radius: 6px;
    display: none;
}

.navItems {
    width: 1128px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
    text-align: center;
    color: #2d065d;
}

.imageIcon {
    align-self: stretch;
    width: 320px;
    border-radius: 8px;
    max-height: 100%;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
}

.paletteIcon {
    width: 20px;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.text8 {
    position: relative;
    line-height: 16px;
    font-weight: 500;
}

.badge1 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 6px;
    background-color: #dceaf5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 10px;
    gap: 4px;
}

.badge3 {
    position: absolute;
    top: 0px;
    left: 93px;
    border-radius: 6px;
    background-color: #dceaf5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 10px;
    gap: 4px;
}

.save {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.shareAllOutlineIcon {
    width: 20px;
    position: relative;
    height: 20px;
}

.share {
    position: relative;
    line-height: 150%;
    font-weight: 500;
}

.commentsSave {
    position: absolute;
    top: 2px;
    left: 554px;
    width: 297px;
    height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    text-align: left;
    font-size: 14px;
    color: #6b7280;
    font-family: Inter;
}

.badgeGroup {
    width: 851px;
    position: relative;
    height: 24px;
    text-align: center;
    color: #003a68;
}

.title {
    align-self: stretch;
    position: relative;
    font-size: 24px;
    line-height: 125%;
    font-family: Inter;
    color: #111928;
}

.preview {
    align-self: stretch;
    position: relative;
    font-size: 16px;
    line-height: 150%;
}

.publishedOnJan {
    position: relative;
    line-height: 150%;
}

.likes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.likes2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    font-family: Inter;
}

.commentsSave1 {
    align-self: stretch;
    width: 658px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.textLink {
    position: relative;
    line-height: 125%;
    font-weight: 600;
}

.button3 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    font-size: 16px;
    color: #562990;
    font-family: Inter;
}

.buttonAuthor {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 851px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.buttonAuthorWrapper {
    align-self: stretch;
    position: relative;
    height: 21px;
    font-size: 14px;
}

.text7 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.blogCard {
    align-self: stretch;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 32px;
    padding: 15px;
}

.blogCards {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 48px;
}

.articles {
    width: 1251px;
    height: 2205px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 24px;
    box-sizing: border-box;
}

.span {
    font-weight: 600;
    font-family: Inter;
    color: #1b1128;
}

.text47 {
    width: 951px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.pageItem {
    align-self: stretch;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
}

.pageItem1 {
    align-self: stretch;
    background-color: #fff;
    border: 1px solid #d6d2db;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
}

.pageItem2 {
    align-self: stretch;
    background-color: #d5c6e9;
    border: 1px solid #d6d2db;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    color: #42167a;
}

.pagination {
    border-radius: 4px;
    border: 1px solid #d6d2db;
    box-sizing: border-box;
    height: 33px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-family: Inter;
}

.tableFooter {
    align-self: stretch;
    border-radius: 0px 0px 8px 8px;
    background-color: #fff;
    border-top: 1px solid #e8e6eb;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    font-size: 14px;
}

.table {
    width: 1275px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.tableWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    color: #6b7280;
    font-family: Roboto;
}

.contentLayouts {
    width: 1275px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-left: 3rem;
}

.content {
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
    text-align: left;
    font-size: 36px;
    color: #1b1128;
    font-family: 'Roboto Flex';
}
</style>